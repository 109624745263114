import React from "react";
import storage from "../../../../Storage";
import socket from "../../../../Socket";
import {__, encode, wait, MIN_DEPOSIT} from "../../../../Helper";
import C from "../../../../Constant";
import Operators from "./Operators";

class Depositing extends React.Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            amount: MIN_DEPOSIT,
            phone: storage.getKey('phone'),
        };
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    submit = (e) => {
        e.preventDefault();

        socket.emit(C.DEPOSIT, encode({
            phone: this.state.phone,
            amount: this.state.amount,
        }));

        wait(1000).then(() => {
            this.setState({submitted: true});
        })
    }

    handleChangeAmount = (e) => {
        let val = e.target.value;
        this.setState({amount: val});
    }

    render() {
        return (
            <Operators/>
        );
    }
}

export default Depositing;