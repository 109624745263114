import React, {Component} from 'react';
import {Modal} from "react-bootstrap";
import {Event, BRAND, CONTACT_NUMBER} from "../../../../Helper";
import {Divider} from "@mui/material";
import YouTubeIcon from '@mui/icons-material/YouTube';
import LaunchIcon from '@mui/icons-material/Launch';
import YouTube from "react-youtube";

class HowToPlay extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            effect: 'pulse',
        };
        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    componentDidMount() {
        Event.on('show_help', this.handleShow);
    }

    handleShow(e) {
        this.setState({show: true, effect: 'pulse'});
    }

    handleClose() {
        this.setState({show: false, effect: 'zoomOut'});
    }

    render() {
        let {show, effect} = this.state;

        return (
            <Modal contentClassName={'custom-modal-content'} size="lg" centered={true} show={show} onHide={this.handleClose}
                   aria-labelledby="promo-md-modal"
                   className={'animated ' + effect}>
                <Modal.Header className={'p-2'} closeButton>
                    <Modal.Title className={'text-custom-yellow'} style={{fontSize: '1.2rem'}}>
                        {BRAND} - NAMNA YA KUCHEZA
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{fontSize: '0.81rem'}} className={'p-2'}>
                    <HelpFieldSet/>
                </Modal.Body>
                <Modal.Footer className={'p-2'}>
                    Kwa mengi, wasiliana nasi: {CONTACT_NUMBER}
                </Modal.Footer>
            </Modal>
        );
    }
}

class HelpFieldSet extends React.Component {

    render() {
        return (
            <>
                <ol style={{paddingLeft: '1rem'}}>
                    <li>Weka kiasi unachotaka kucheza mfano <strong>Tsh 200</strong>, kisha bonyeza neno <strong>BET</strong></li>
                    <li>Mchezo ukianza unaweza kubonyeza neno <strong>CASHOUT</strong> ili kutoa pesa zako</li>
                    <li>Kama mchezo utaisha kabla hujacash out utakuwa umepoteza</li>
                    <li>Unaweza kuweka neno <strong>AUTO CASH OUT</strong> ili system iweze kucash out moja kwa moja</li>
                    <li>Unaweza pia kuweka <strong>AUTO BET</strong> option ili system iweze kukubetia moja kwa moja</li>
                </ol>

                <Divider textAlign={"left"}>Maana ya Maneno</Divider>

                <table className={"table table-striped mb-0 text-white"}>
                    <tr>
                        <td><strong className={"text-warning text-nowrap"}>Amount:</strong></td>
                        <td>Kiasi ulichochagua kubet. Mfano <span className="text-warning">200.00</span></td>
                    </tr>
                    <tr style={{borderTop: '1px solid #f5f5f552'}}>
                        <td><strong className={"text-warning text-nowrap"}>Auto Cashout:</strong></td>
                        <td>Kiasi utakachochagua kutoa moja kwa moja.</td>
                    </tr>
                    <tr style={{borderTop: '1px solid #f5f5f552'}}>
                        <td><strong className={"text-warning text-nowrap"}>Auto Bet:</strong></td>
                        <td>Kiasi utakachochagua kubet moja kwa moja.</td>
                    </tr>
                    <tr style={{borderTop: '1px solid #f5f5f552'}}>
                        <td><strong className={"text-warning text-nowrap"}>Multiplier:</strong></td>
                        <td>Hii ni idadi ya odds kabla mchezo haujaisha. Kiasi cha pesa ulichobet kinazidishwa na kiasi hiki cha odds</td>
                    </tr>
                </table>
            </>
        );
    }
}

class YouTubeHelp extends React.Component {
    render() {
        const opts = {
            //height: '195',
            width: '100%',
            playerVars: {
                // https://developers.google.com/youtube/player_parameters
                autoplay: 1,
            },
        };

        return <YouTube videoId="nRNdbIKf0yI" opts={opts} onReady={this._onReady}/>;
    }

    _onReady(event) {
        // access to player in all event handlers via event.target
        event.target.pauseVideo();
    }
}

export default HowToPlay;