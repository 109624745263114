import React from "react";
import $ from 'jquery';
import {Typography} from "@mui/material";
import {MIN_DEPOSIT} from "../../../../Helper";

class Operators extends React.Component {
    constructor(props) {
        super(props);

        this.state = {}
    }

    componentDidMount() {
        $('.xx-tabgroup > div').hide();
        $('.xx-tabgroup > div:first-of-type').show();
        $('.xx-tabs a').click(function (e) {
            e.preventDefault();
            let $this = $(this),
                tabgroup = '#' + $this.parents('.xx-tabs').data('tabgroup'),
                others = $this.closest('li').siblings().children('a'),
                target = $this.attr('href');
            others.removeClass('active');
            $this.addClass('active');
            $(tabgroup).children('div').hide();
            $(target).show();

        })
    }

    render() {
        return (
            <>
                <div className={"xx-wrapper mt-2"}>
                    <ul className={"xx-tabs xx-clearfix"} data-tabgroup={"first-tab-group"}>
                        <li><a href="#xx-tab-m-pesa" className="active">M-PESA</a></li>
                        <li><a href="#xx-tab-tigo">tiGO</a></li>
                        <li><a href="#xx-tab-airtel">Airtel</a></li>
                    </ul>
                    <section id={"first-tab-group"} className={"xx-tabgroup"}>
                        <div id="xx-tab-m-pesa">
                            <p className={'m-0'}>1.Piga *150*00#</p>
                            <p className={'m-0'}>2.Chagua 4-Lipa Bili</p>
                            <p className={'m-0'}>3.Chagua 5-Lipa kwa MPESA</p>
                            <p className={'m-0'}>4.Weka namba ya kampuni (Ingiza namba 909192)</p>
                            <p className={'m-0'}>5.Kumbukumbu No: Weka Neno (PAISHA)</p>
                            <p className={'m-0'}>6.Weka Kiasi chako kuanzia Tsh 1,000 au zaidi</p>
                        </div>
                        <div id="xx-tab-tigo">
                            <p className={'m-0'}>1.Piga *150*01#</p>
                            <p className={'m-0'}>2.Chagua 4-Lipa Bili</p>
                            <p className={'m-0'}>3.Chagua 3-Ingiza namba ya kampuni (909192)</p>
                            <p className={'m-0'}>4.Kumbukumbu No: Weka Neno (PAISHA)</p>
                            <p className={'m-0'}>5.Weka Kiasi chako kuanzia Tsh 1,000 au zaidi</p>
                        </div>
                        <div id="xx-tab-airtel">
                            <p className={'m-0'}>1.Piga *150*60#</p>
                            <p className={'m-0'}>2.Chagua 5-Lipa Bili</p>
                            <p className={'m-0'}>3.Chagua 6-Bahati Nasibu</p>
                            <p className={'m-0'}>4.Chagua 27-Shabiki Bingwa</p>
                            <p className={'m-0'}>5.Kumbukumbu No: Weka Neno (PAISHA)</p>
                            <p className={'m-0'}>6.Weka Kiasi chako kuanzia Tsh 1,000 au zaidi</p>
                        </div>
                    </section>
                </div>

                <Typography className={'m-2'} sx={{color: 'white'}} gutterBottom>
                    <dt className={'text-warning font-weight-bold'}>Other Details:</dt>
                    Minimum amount: {MIN_DEPOSIT}/-<br/>
                    Maximum amount: 3,000,000/-<br/>
                    Daily Transaction Limit: 6,000,000/-
                </Typography>
            </>
        );
    }
}

{/*
<div className={"container-fluid"}>
                <div className={"row text-center"}>
                    <div className={'col-4 p-0'}>
                        <div className={"my-gradient-opposite mr-1 border-right"}>
                            <span className={'text-warning'}>M-PESA</span><br/>
                            <span className={'deposit-span'}>
                            <p className={'m-0'}>1.Piga *150*00#</p>
                            <p className={'m-0'}>2.Chagua 4-Lipa Bili</p>
                            <p className={'m-0'}>3.Chagua 5-Lipa kwa MPESA</p>
                            <p className={'m-0'}>4.Weka namba ya kampuni (Ingiza namba 909192)</p>
                            <p className={'m-0'}>5.Kumbukumbu No: Weka Neno (PAISHA)</p>
                            <p className={'m-0'}>6.Weka Kiasi chako kuanzia Tsh 1,000 au zaidi</p>
                            </span>
                        </div>
                    </div>
                    <div className={'col-4 p-0'}>
                        <div className={"my-gradient-opposite mr-1 border-right"}>
                            <span className={'text-warning'}>tiGO</span><br/>
                            <span className={'deposit-span'}>
                            <p className={'m-0'}>1.Piga *150*01#</p>
                            <p className={'m-0'}>2.Chagua 4-Lipa Bili</p>
                            <p className={'m-0'}>3.Chagua 3-Ingiza namba ya kampuni (909192)</p>
                            <p className={'m-0'}>4.Kumbukumbu No: Weka Neno (PAISHA)</p>
                            <p className={'m-0'}>5.Weka Kiasi chako kuanzia Tsh 1,000 au zaidi</p>
                            </span>
                        </div>
                    </div>
                    <div className={'col-4 p-0'}>
                        <div className={"my-gradient-opposite mr-1 border-right"}>
                            <span className={'text-warning'}>Airtel</span><br/>
                            <span className={'deposit-span'}>
                            <p className={'m-0'}>1.Piga *150*60#</p>
                            <p className={'m-0'}>2.Chagua 5-Lipa Bili</p>
                            <p className={'m-0'}>3.Chagua 6-Bahati Nasibu</p>
                            <p className={'m-0'}>4.Chagua 27-Shabiki Bingwa</p>
                            <p className={'m-0'}>5.Kumbukumbu No: Weka Neno (PAISHA)</p>
                            <p className={'m-0'}>6.Weka Kiasi chako kuanzia Tsh 1,000 au zaidi</p>
                            </span>
                        </div>
                    </div>
                </div>

                <Typography className={'m-2'} sx={{color: 'white'}} gutterBottom>
                    <dt className={'text-warning font-weight-bold'}>Other Details:</dt>
                    Minimum amount: {MIN_DEPOSIT}/-<br/>
                    Maximum amount: 3,000,000/-<br/>
                    Daily Transaction Limit: 6,000,000/-
                </Typography>
            </div>
*/
}

export default Operators;